// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("/builds/zaven/zaven-website-react/src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-job-js": () => import("/builds/zaven/zaven-website-react/src/templates/Job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-pages-404-js": () => import("/builds/zaven/zaven-website-react/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-team-js": () => import("/builds/zaven/zaven-website-react/src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-agile-lifecycle-management-js": () => import("/builds/zaven/zaven-website-react/src/pages/agile-lifecycle-management.js" /* webpackChunkName: "component---src-pages-agile-lifecycle-management-js" */),
  "component---src-pages-career-js": () => import("/builds/zaven/zaven-website-react/src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("/builds/zaven/zaven-website-react/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/builds/zaven/zaven-website-react/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/builds/zaven/zaven-website-react/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-projects-js": () => import("/builds/zaven/zaven-website-react/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-mobile-app-development-poland-js": () => import("/builds/zaven/zaven-website-react/src/pages/services/mobile-app-development-poland.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-poland-js" */),
  "component---src-pages-services-poland-software-outsourcing-js": () => import("/builds/zaven/zaven-website-react/src/pages/services/poland-software-outsourcing.js" /* webpackChunkName: "component---src-pages-services-poland-software-outsourcing-js" */),
  "component---src-pages-services-web-app-development-poland-js": () => import("/builds/zaven/zaven-website-react/src/pages/services/web-app-development-poland.js" /* webpackChunkName: "component---src-pages-services-web-app-development-poland-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/zaven/zaven-website-react/.cache/data.json")

